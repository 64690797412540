import { template as template_f235c91c5ae94635b7e6d0ac025ccd58 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f235c91c5ae94635b7e6d0ac025ccd58(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
