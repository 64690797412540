import { template as template_be1a9a4b8a104b5f8932e866519a97ac } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_be1a9a4b8a104b5f8932e866519a97ac(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
